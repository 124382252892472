import React, { useEffect, useState } from "react";
import '../styles/styles.css';

const ListComponent = (props) => {

    const [listCategories, setListCategories] = useState(null);
    const [listModels, setListModels] = useState(null);
    
    const fetchData = async() => {
        
        try {
            const response = await fetch("/api/categories");
            const json = await response.json();
            console.log(json);
        } catch (error) {
            console.log("error", error);
        }
    }

    useEffect(() => {
        fetch("/api/categories")
        .then((res) => res.json())
        .then((data) => {
            console.log('sss', data)
            setListCategories(data)
        });
    }, []);

    // const loadModels = () => {
    //     fetch("/api/getmodels")
    //     .then((res) => res.json())
    //     .then((data) => {
    //         console.log('loadModels', data)
    //        // setListModels(data);
    //     });
    // }

    const handleChange = (event) => {
        
        if(isNaN(event.target.value)){
            console.log(event.target.value);
            props.onCategoryChange(null);
            setListModels(null);
        } else {
            console.log(listCategories[event.target.value].models);
            props.onCategoryChange(listCategories[event.target.value]);
            setListModels(listCategories[event.target.value].models);
        }
    }

    const selectModel = (index) => {
        //console.log(listModels[index]);
        props.onModelChange(listModels[index]);
    }

    return (
        
        <div className="select-container">
            <select defaultValue="select" onChange={handleChange}>
                <option value="select" >Select Category</option>
                {listCategories &&
                listCategories.map((data, index) => (
                    <option value={index} key={index}>
                        {data.name}
                    </option>
                ))}
            </select>
            <div className="list-container">
                <ul>
                    {
                        listModels &&
                        listModels.map((data, index) => {
                            return(
                                <li key={index}>
                                    <a href="#" onClick={() => selectModel(index)}>{data.name}</a>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </div>
    )
}

export default ListComponent;