import React, { useRef } from 'react';
//import "./App.css";
import './styles/styles.css';
//import logo from "./logo.svg";
import ListComponent from "./components/ListComponent"
import axios from 'axios';

function App() {

  const [selectedImage, setSelectedImage] = React.useState("");
  const [imagePath, setImagePath] = React.useState("");
  const [objectJsonAsString, setObjectJsonAsString] = React.useState("");
  const [objectJson, setObjectJson] = React.useState([]);
  const [time, setTime] = React.useState("0");
  const [loading, setLoading] = React.useState(false);

  const [category, setCategory] = React.useState(null);
  const [model, setModel] = React.useState(null);

  /*React.useEffect(() => {
    fetch("/api")
      .then((res) => res.json())
      .then((data) => setData(data.message));
  }, []);*/

  const onCategoryChange = (data) => {
    setCategory(data);
    setImagePath("");
    setModel(null);
  }

  const onModelChange = (data) => {
    //console.log("ONMODELCHANGE", data);
    setModel(data);
  }

  const inputFile = useRef(null);


  const isString = (value) => {
    return typeof value === 'string' || value instanceof String;
  }

  const getFileName = (val) => 
  {
    val = val.split("/");
    val = val[val.length-1];
    return val;
  }

  const runTest = async () => {
    setLoading(true);
    setObjectJson("");
    setTime(0);

    let modelName = getFolderName(category.name);
    let versionName = getFolderName(model.name);
    let fileName = getFileName(model.path);
    let fullPath = "./models/" + modelName + "/" + versionName + "/";
    
    console.log("model",model);
    if(isString(selectedImage)) {
      const requestOptions1 = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          imagePath: selectedImage,
          modelPath: fullPath,
          weightLocalPath: fullPath + fileName,
        })
      };

      //console.log("requestOptions1", requestOptions1);
      fetch("/api/execute", requestOptions1)
      .then((res) => res.json())
      .then((data) => {
        console.log("data", data);
        
        let tmp = JSON.parse(data);
        setImagePath(tmp.imagedata);
        setObjectJsonAsString(JSON.stringify(tmp.objects));
        setObjectJson(tmp.objects);
        setTime(tmp.time);
        setLoading(false);
      });


    } else {
      const formData = new FormData();
      formData.append("file", selectedImage);
      formData.append("fileName", selectedImage.name);
      fetch("/api/upload", {
        method: 'POST',
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          //console.log(">>>>>>>>>", data);

          //let modelName = getFolderName(category.name);
          //let versionName = getFolderName(model.name);
          //let fullPath = "./models/" + modelName + "/" + versionName + "/";

          const requestOptions2 = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              imagePath: data.path,
              modelPath: fullPath,
              weightLocalPath: fullPath + fileName,
            })
          };

          //console.log("requestOptions2", requestOptions2);
          fetch("/api/execute", requestOptions2)
            .then((res) => res.json())
            .then((data) => {
              //console.log();
              let tmp = JSON.parse(data);
              setImagePath(tmp.imagedata);
              setObjectJsonAsString(JSON.stringify(tmp.objects));
              setObjectJson(tmp.objects);
              setTime(tmp.time);
              setLoading(false);
            });
        });
    }

    
  }

  const getFolderName = (data) => {
    data = data.split(" ").join("");
    data = data.toLowerCase();
    return data;
  }

  const downloadModel = () => {
    if (model) {
      //console.log("model", model);
      let modelname = getFolderName(category.name);
      //console.log("modelname", modelname);

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          folder: modelname,
          subfolder: getFolderName(model.name),
          path: model.path
        })
      };
      fetch("/api/downloadmodels", requestOptions)
        .then((res) => res.json())
        .then((data) => {
          console.log("downloadModel", data);
        });
    }
  }

  const onFileSelect = (event) => {
    setSelectedImage(event.target.files[0]);
    setImagePath(URL.createObjectURL(event.target.files[0]));
  }

  const onFilePathEdit = (event) => {
    //console.log(event.target.value);
    setSelectedImage(event.target.value);
    setImagePath(event.target.value);
  }

  return (
    <div className="main-container">

      <div className="top-container">
        <div className="Title">
          <h2>MODEL EXPLORER</h2>
          <p>A Wowyow Initiative</p>
        </div>

        <div className="logo">
        <img src="./images/logo.png" />
        </div>

      </div>

      <div className="sub-container">

        <div className="LeftPanel">
          <ListComponent
            onCategoryChange={onCategoryChange}
            onModelChange={onModelChange}
          />
        </div>

        <div className="image-container">


          <div className="label-container">

            <span>{model ? category.name + " > " + model.name : "No Model Selected"}</span>

            <button disabled={!model} onClick={downloadModel}>
              Download 
            </button>

          </div>


          <div className="image-time-container">

            <div>
              <img className='ImageStyle' src={imagePath} />
              <div className="image-footer-container">
                <input type='file' onChange={onFileSelect} />
                <input type='text' onChange={onFilePathEdit} />
                {/* disabled={!model || !imagePath} */}
                <button disabled={!model || !imagePath} onClick={runTest}>
                  Run Test
                </button>
              </div>
            </div>

            <div className="time-container">
              <h5>Model details: <span>{model ? model.description : ''}</span></h5>
              <h5>Time: <span>{time} ms {loading ? <img src="./images/loader.gif" className="loader"  />:null}</span></h5>
              <p>OBJECTS: found {objectJson && objectJson.length}</p>
              <div className="object-container">
                <p>{objectJsonAsString}</p>
              </div>

            </div>

          </div>


        </div>



      </div>





    </div>

  );
}

export default App;
