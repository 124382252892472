import React, { useState, useEffect } from 'react';
import './styles/styles.css';
import App from './App';
import { WYLoginPopup } from './ReactLoginUI';

window.ENV = {
  ACCOUNTS_ENDPOINT: 'https://wowyow-react-login.dev.cloud9ws.com',
  GOOGLE_CLIENT_ID: '640768921283-rid8vtsdqco3herb33lour02h6vvhnc2.apps.googleusercontent.com'
};
if (window.location.hostname === 'labs.wowyow.com') {
  window.ENV.ACCOUNTS_ENDPOINT = 'https://accounts.wowyow.com';
}

function AccountMiddleware () {
  
  const [loggedIn, setLoggedIn] = useState(false);
  const [showLogin, setShowLogin] = useState(true);
  
  const onClose = () => {
    
  };
  
  const onSuccess = async (user) => {
    if (!user.platforms.includes('labs')) {
      return;
    }
    const response = await (await fetch('/api/session/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        sessionToken: user.sessionToken
      })
    })).json();
    if (response.status === 'success') {
      setLoggedIn(true);
    }
  }
  
  const onFailure = () => {
    
  };
  
  const isLoggedIn = async () => {
    const response = await (await fetch('/api/session/logged-in', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
    })).json();
    if (response.status === 'success') {
      setLoggedIn(true);
    }
  };
  
  useEffect(() => {
    isLoggedIn()
  }, []);
  
  return (
    <div>
      {!loggedIn ?
        <WYLoginPopup show={showLogin} onClose={onClose} onSuccess={onSuccess} onFailure={onFailure} platform={'labs'}/>
      : 
        <App />
      } 
    </div>
  );
}

export default AccountMiddleware;
