import React, { useState, useEffect, useRef } from 'react';
import { GoogleLogin } from 'react-google-login';
import './style/global.css';
import './style/main.css';
import './style/popup.css';
import './style/screen.css';
import './style/loading.css';
import './style/buttons.css';
import './style/inputs.css';
import './style/text.css';
import './style/logo.css';

const IFRAME = (() => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
})();

function WYLoginPopup ({ screen: overrideScreen, show, onClose, onSuccess, onFailure, platform }) {
  
  const [home, setHome] = useState([
    'wowyow.io', 
    'www.wowyow.io', 
    'wowyow.com', 
    'www.wowyow.com', 
    'wowyow-react-login.dev.cloud9ws.com',
    'wow-yow.webflow.io'
  ].includes(window.location.hostname));
  
  const [user, setUser] = useState({
    userId: '',
    email: '',
    platforms: [],
    sessionToken: '',
    name: '',
    username: '', 
    profileImage: '',
    twoFactor: false,
    roles: ['user']
  });
  
  useEffect(() => {
    if (user.userId) {
      onSuccess(user);
    }
  }, [user]);
  
  const [clientId, setClientId] = useState(window.ENV.GOOGLE_CLIENT_ID);
  
  const [loading, setLoading] = useState(true);
  
  const [validationSuccess, setValidationSuccess] = useState('');
  const [validationWarning, setValidationWarning] = useState('');
  const [validationError, setValidationError] = useState('');
  
  const resetValidation = () => {
    setValidationSuccess('');
    setValidationWarning('');
    setValidationError('');
  };
  
  const post = async (path, body) => {
    try {
      let response = await (await fetch(path, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: body ? JSON.stringify(body) : undefined
      })).json();
      return response;
    } catch (err) {
      console.log(err);
    }
    return {};
  };
  
  useEffect(() => {
    if (overrideScreen !== screen) {
      setScreen(overrideScreen);
      setLoading(false);
    }
  }, [overrideScreen]);
  
  const [accounts, setAccounts] = useState([]);
  
  const getAccounts = async (firstVisit = false) => {
    let response = await post(`${window.ENV.ACCOUNTS_ENDPOINT}/api/session/accounts`);
    if (response.status === 'success') {
      setAccounts(response.data.accounts.slice(-2));
      let lastAccount = response.data.accounts[response.data.accounts.length - 1];
      if (firstVisit && lastAccount) {
        let params = new URLSearchParams(window.location.search);
        if (params.has('autologin')) {
          setUser(lastAccount);
        }
      }
    }
  };
  
  const firstVisit = async () => {
    setLoading(true);
    await getAccounts(true);
    setScreen('welcome');
    setLoading(false);
  };
  
  useEffect(() => {
    if (show) {
      firstVisit();
    }
  }, [show]);
  
  const close = () => {
    if (onClose) {
      onClose();
    }
  };
  
  const responseGoogleSuccess = async (response) => {
    setLoading(true);
    let auth = await post(`${window.ENV.ACCOUNTS_ENDPOINT}/api/session/google/auth`, {
      email: response.profileObj.email,
      accessToken: response.tokenObj.id_token
    });
    setUser({
      userId: auth.data.userId,
      email: auth.data.email,
      name: auth.data.name,
      username: auth.data.username,
      profileImage: auth.data.profileImage,
      platforms: auth.data.platforms || [],
      sessionToken: auth.data.sessionToken,
      twoFactor: auth.data.twoFactor,
      roles: auth.data.roles
    });
    if (!platform || !(auth.data.platforms || []).includes(platform)) {
      setScreen('platforms');
      setLoading(false);
    }
    getAccounts();
  };
  
  const responseGoogleFailure = async (response) => {
    setScreen('welcome');
  };
  
  const loginButtonHandler = () => {
    setScreen('email');
    setTimeout(() => { emailInput.current.focus(); });
  };
  
  const [password, setPassword] = useState('');
  const [rpassword, setRPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showRPassword, setShowRPassword] = useState(false);
  const [token, setToken] = useState('');
  const [email, setEmail] = useState('');
  
  const emailInput = useRef(null);
  const passwordInput = useRef(null);
  const tokenInput = useRef(null);
  const verificationCodeInput = useRef(null);
  const signupPasswordInput = useRef(null);
  
  const emailHandler = async () => {
    setLoading(true);
    resetValidation();
    let response = await post(`${window.ENV.ACCOUNTS_ENDPOINT}/api/session/exists`, {
      email: email
    });
    if (response.status !== 'success') {
      setValidationError(response.message);
    } else {
      if (response.data.state === 'unverified') {
        setScreen('verification');
        setTimeout(() => { verificationCodeInput.current.focus(); });
      } else if (response.data.state === 'verified') {
        setScreen('signup');
        setTimeout(() => { signupPasswordInput.current.focus(); });
      } else if (response.data.state === 'registered') {
        if (response.data.method === 'password') {
          setScreen('signin-password');
          setTimeout(() => { passwordInput.current.focus(); });
        } else if (response.data.method === 'fingerprint') {
          setScreen('signin-fingerprint');
        }
      }
    }
    setLoading(false);
  };
  
  const signInHandler = async () => {
    setLoading(true);
    resetValidation();
    let response = await post(`${window.ENV.ACCOUNTS_ENDPOINT}/api/session/login`, {
      email: email,
      password: password,
    });
    if (response.status !== 'success') {
      setValidationError(response.message);
      setLoading(false);
    } else {
      if (response.data.twoFactor) {
        setScreen('2-factor');
        setTimeout(() => { tokenInput.current.focus(); });
        setLoading(false);
      } else {
        setUser({
          userId: response.data.userId,
          email: response.data.email,
          name: response.data.name,
          username: response.data.username,
          profileImage: response.data.profileImage,
          platforms: response.data.platforms || [],
          sessionToken: response.data.sessionToken,
          twoFactor: response.data.twoFactor,
          roles: response.data.roles
        });
        if (!platform || !(response.data.platforms || []).includes(platform)) {
          setScreen('platforms');
          setLoading(false);
        }
        getAccounts();
      }
    }
  };
  
  const signIn2FactorHandler = async () => {
    setLoading(true);
    resetValidation();
    let response = await post(`${window.ENV.ACCOUNTS_ENDPOINT}/api/session/login`, {
      email: email,
      password: password,
      token: token
    });
    if (response.status !== 'success') {
      setValidationError(response.message);
      setLoading(false);
    } else {
      setUser({
        userId: response.data.userId,
        email: response.data.email,
        name: response.data.name,
        username: response.data.username,
        profileImage: response.data.profileImage,
        platforms: response.data.platforms || [],
        sessionToken: response.data.sessionToken,
        twoFactor: response.data.twoFactor,
        roles: response.data.roles
      });
      if (!platform || !(response.data.platforms || []).includes(platform)) {
        setScreen('platforms');
        setLoading(false);
      }
      getAccounts();
    }
  };
  
  const chooseAccount = async (account) => {
    setLoading(true);
    resetValidation();
    setEmail(account.email);
    let response = await post(`${window.ENV.ACCOUNTS_ENDPOINT}/api/session/exists`, {
      email: account.email
    });
    if (response.status !== 'success') {
      setValidationError(response.message);
    } else {
      if (response.data.state === 'unverified') {
        setScreen('verification');
        setTimeout(() => { verificationCodeInput.current.focus(); });
      } else if (response.data.state === 'verified') {
        setScreen('signup');
        setTimeout(() => { signupPasswordInput.current.focus(); });
      } else if (response.data.state === 'registered') {
        if (response.data.method === 'password') {
          setScreen('signin-password');
          setTimeout(() => { passwordInput.current.focus(); });
        } else if (response.data.method === 'fingerprint') {
          setScreen('signin-fingerprint');
        }
      }
    }
    setLoading(false);
  };
  
  const platformsHandler = (platform) => {
    switch (platform) {
      case 'publishers':
        if (window.location.hostname === 'wowyow-react-login.dev.cloud9ws.com') {
          window.open('https://wowyow-dashboard-v2.dev.cloud9ws.com/login?autologin=1');
        } else {
          window.open('https://publishers.wowyow.com/?autologin=1');
        }
      break;
      case 'yield':
        if (window.location.hostname === 'wowyow-react-login.dev.cloud9ws.com') {
          window.open('https://wowyow-dashboard-v2.dev.cloud9ws.com/login?autologin=1');
        } else {
          window.open('https://yield.wowyow.com/login?autologin=1');
        }
      break;
      case 'marketers':
        if (window.location.hostname === 'wowyow-react-login.dev.cloud9ws.com') {
          window.open('https://wowyow-dashboard-v2.dev.cloud9ws.com/login?autologin=1');
        } else {
          window.open('https://marketers.wowyow.com/login?autologin=1');
        }
      break;
      case 'programmatic':
        if (window.location.hostname === 'wowyow-react-login.dev.cloud9ws.com') {
          window.open('https://wowyow-dashboard-v2.dev.cloud9ws.com/login?autologin=1');
        } else {
          window.open('https://programmatic.wowyow.com/login?autologin=1');
        }
      break;
      case 'ai':
        if (window.location.hostname === 'wowyow-react-login.dev.cloud9ws.com') {
          window.open('https://wowyow-dashboard-v2.dev.cloud9ws.com/login?autologin=1');
        } else {
          window.open('https://ai.wowyow.com/login?autologin=1');
        }
      break;
      case 'labs':
        if (window.location.hostname === 'wowyow-react-login.dev.cloud9ws.com') {
          window.open('https://wowyow-dashboard-v2.dev.cloud9ws.com/login?autologin=1');
        } else {
          window.open('https://labs.wowyow.com/login?autologin=1');
        }
      break;
      case 'console':
        if (window.location.hostname === 'wowyow-react-login.dev.cloud9ws.com') {
          window.open('https://wowyow-dashboard-v2.dev.cloud9ws.com/login?autologin=1');
        } else {
          window.open('https://console.wowyow.com/login?autologin=1');
        }
      break;
    }
  };
  
  const requestAccess = async () => {
    setLoading(true);
    resetValidation();
    let response = await post(`${window.ENV.ACCOUNTS_ENDPOINT}/api/session/request`, {
      platform: platform
    });
    if (response.status !== 'success') {
      setValidationError(response.message);
    } else {
      setValidationSuccess(response.message);
    }
    setLoading(false);
  };
  
  const [verificationCode, setVerificationCode] = useState('');
  
  useEffect(() => {
    if (verificationCode.length === 6) {
      verificationHandler();
    }
  }, [verificationCode]);
  
  const verificationHandler = async () => {
    setLoading(true);
    resetValidation();
    let response = await post(`${window.ENV.ACCOUNTS_ENDPOINT}/api/session/verify`, {
      email: email,
      verificationCode: verificationCode
    });
    if (response.status !== 'success') {
      setValidationError(response.message);
    } else {
      setScreen('signup');
    }
    setLoading(false);
  };
  
  const signUpHandler = async () => {
    setLoading(true);
    resetValidation();
    let response = await post(`${window.ENV.ACCOUNTS_ENDPOINT}/api/session/signup`, {
      email: email,
      password: password,
      rpassword: rpassword
    });
    if (response.status !== 'success') {
      setValidationError(response.message);
      setLoading(false);
    } else {
      let login = await post(`${window.ENV.ACCOUNTS_ENDPOINT}/api/session/login`, {
        email: email,
        password: password
      });
      if (login.status !== 'success') {
        setValidationError(login.message);
        setLoading(false);
      } else {
        setUser({
          userId: login.data.userId,
          email: login.data.email,
          name: login.data.name,
          username: login.data.username,
          profileImage: login.data.profileImage,
          platforms: login.data.platforms || [],
          sessionToken: login.data.sessionToken,
          twoFactor: login.data.twoFactor,
          roles: login.data.roles
        });
        if (!platform || !(login.data.platforms || []).includes(platform)) {
          setScreen('platforms');
          setLoading(false);
        }
        getAccounts();
      }
    }
  };
  
  const forgotPasswordHandler = async () => {
    setLoading(true);
    resetValidation();
    let response = await post(`${window.ENV.ACCOUNTS_ENDPOINT}/api/session/forgot-password`, {
      email: email
    });
    if (response.status !== 'success') {
      setValidationError(response.message);
    } else {
      setScreen('re-verification');
    }
    setLoading(false);
  };
  
  const [reVerificationCode, setReVerificationCode] = useState('');
  
  useEffect(() => {
    if (reVerificationCode.length === 6) {
      reVerificationHandler();
    }
  }, [reVerificationCode]);
  
  const reVerificationHandler = async () => {
    setLoading(true);
    resetValidation();
    let response = await post(`${window.ENV.ACCOUNTS_ENDPOINT}/api/session/reverify`, {
      email: email,
      verificationCode: reVerificationCode
    });
    if (response.status !== 'success') {
      setValidationError(response.message);
    } else {
      setScreen('reset-password');
    }
    setLoading(false);
  };
  
  const resendReVerificationCode = async () => {
    setLoading(true);
    resetValidation();
    let response = await post(`${window.ENV.ACCOUNTS_ENDPOINT}/api/session/forgot-password`, {
      email: email
    });
    if (response.status !== 'success') {
      setValidationError(response.message);
    }
    setLoading(false);
  };
  
  const resetPasswordHandler = async () => {
    setLoading(true);
    resetValidation();
    let response = await post(`${window.ENV.ACCOUNTS_ENDPOINT}/api/session/reset-password`, {
      email: email,
      verificationCode: reVerificationCode,
      password: password,
      rpassword: rpassword
    });
    if (response.status !== 'success') {
      setValidationError(response.message);
      setLoading(false);
    } else {
      let login = await post(`${window.ENV.ACCOUNTS_ENDPOINT}/api/session/login`, {
        email: email,
        password: password
      });
      if (login.status !== 'success') {
        setValidationError(login.message);
        setLoading(false);
      } else {
        setUser({
          userId: login.data.userId,
          email: login.data.email,
          name: login.data.name,
          username: login.data.username,
          profileImage: login.data.profileImage,
          platforms: login.data.platforms || [],
          sessionToken: login.data.sessionToken,
          twoFactor: login.data.twoFactor,
          roles: login.data.roles
        });
        if (!platform || !(login.data.platforms || []).includes(platform)) {
          setScreen('platforms');
          setLoading(false);
        }
        getAccounts();
      }
    }
  };
  
  const resendVerificationCode = async () => {
    setLoading(true);
    resetValidation();
    let response = await post(`${window.ENV.ACCOUNTS_ENDPOINT}/api/session/resend`, {
      email: email
    });
    if (response.status !== 'success') {
      setValidationError(response.message);
    }
    setLoading(false);
  };
  
  const [screen, setScreenDeligate] = useState('welcome');
  const setScreen = (input) => {
    resetValidation();
    setScreenDeligate(input);
  };
  
  return (
    <div className={`wy-rl-login ${show ? 'show' : ''}`} onClick={close}>
      <div className="wy-rl-popup" onClick={e => e.stopPropagation()}>
        
        <div className={`wy-rl-screen s-1 ${screen === 'welcome' ? 'active' : ''}`}>
          <div className="wy-rl-logo"></div>
          <h2 className="wy-rl-title">Sign in / Sign up</h2>
          <p className="wy-rl-subtitle">to WowYow Platform</p>
          {accounts.map(account => {
            if (account.googleAuth) {
              return (
                <GoogleLogin
                  key={account.email}
                  className="google-login-button"
                  clientId={clientId}
                  render={renderProps => (
                    <button key={account.email} onClick={renderProps.onClick} disabled={renderProps.disabled} className="wy-rt-classic-button">{account.email} (Google)</button>
                  )}
                  loginHint={account.email}
                  buttonText={account.email}
                  onSuccess={responseGoogleSuccess}
                  onFailure={responseGoogleFailure}
                  cookiePolicy={'single_host_origin'}
                />
              );
            } else {
              return (
                <button key={account.email} className="wy-rt-classic-button" onClick={e => chooseAccount(account)}>{account.email}</button>
              );
            }
          })}
          <br />
          <button className="wy-rt-classic-button" onClick={loginButtonHandler}>WowYow Account</button>
          <GoogleLogin
            className="google-login-button"
            clientId={clientId}
            buttonText="Sign In with Google"
            onSuccess={responseGoogleSuccess}
            onFailure={responseGoogleFailure}
            cookiePolicy={'single_host_origin'}
            prompt="select_account"
          />
        </div>
        
        <div className={`wy-rl-screen s-2 ${screen === 'email' ? 'active' : ''}`}>
          <button className="wy-rl-back-button" onClick={e => setScreen('welcome')}>Back</button>
          <div className="wy-rl-logo"></div>
          <h2 className="wy-rl-title">Sign in / Sign up</h2>
          <p className="wy-rl-subtitle">WowYow Account</p>
          <label className="wy-rl-label" htmlFor="email">Your email address</label>
          <input type="email" id="email" disabled={loading} value={email} onChange={e => setEmail(e.target.value)} ref={emailInput} placeholder="user@wowyow.com..." onKeyPress={e => { if (e.key === 'Enter') { emailHandler(); }}}/>
          <span className="wy-rl-caption">
            <span className="wy-rl-success">{validationSuccess}</span>
            <span className="wy-rl-warning">{validationWarning}</span>
            <span className="wy-rl-error">{validationError}</span>
          </span>
          <button className="wy-rt-classic-button" onClick={emailHandler}>
            <span>Next</span>
            <div className={`wy-rt-spinner ${loading ? 'active' : ''}`}>
              <div className="wy-rt-button-spinner"><span></span></div>
            </div>
          </button>
        </div>
        
        <div className={`wy-rl-screen s-2 ${screen === 'signin-password' ? 'active' : ''}`}>
          <button className="wy-rl-back-button" onClick={e => setScreen('email')}>Back</button>
          <div className="wy-rl-email" onClick={e => setScreen('welcome')}>{email} (change)</div>
          <div className="wy-rl-logo"></div>
          <h2 className="wy-rl-title">Sign in</h2>
          <p className="wy-rl-subtitle">WowYow Platform Login</p>
          <label className="wy-rl-label" htmlFor="password_1">Your password</label>
          <div className="wy-rl-input-group">
            <input className="wy-rl-password" disabled={loading} type={showPassword ? 'text' : 'password'} id="password_1" value={password} onChange={e => setPassword(e.target.value)} ref={passwordInput} onKeyPress={e => { if (e.key === 'Enter') { signInHandler(); }}}/>
            <span className="wy-rl-show-password" onClick={e => setShowPassword(!showPassword)}>{showPassword ? 'Hide' : 'Show'}</span>
          </div>
          <span className="wy-rl-caption">
            <span className="wy-rl-success">{validationSuccess}</span>
            <span className="wy-rl-warning">{validationWarning}</span>
            <span className="wy-rl-error">{validationError}</span>
          </span>
          <button className="wy-rt-classic-button" onClick={signInHandler}>
            <span>Sign In</span>
            <div className={`wy-rt-spinner ${loading ? 'active' : ''}`}>
              <div className="wy-rt-button-spinner"><span></span></div>
            </div>
          </button>
          <button className="wy-rt-text-button" onClick={forgotPasswordHandler}>Forgot password</button>
        </div>
        
        <div className={`wy-rl-screen s-2 ${screen === 'signin-fingerprint' ? 'active' : ''}`}>
          <button className="wy-rl-back-button" onClick={e => setScreen('email')}>Back</button>
          <div className="wy-rl-email" onClick={e => setScreen('welcome')}>{email} (change)</div>
          <div className="wy-rl-logo"></div>
          <h2 className="wy-rl-title">Sign in</h2>
          <p className="wy-rl-subtitle">Use your fingerprint</p>
          <div className="wy-rl-fingerprint-image"></div>
          <button className="wy-rt-text-button" onClick={e => setScreen('signin-password')}>Use password instead</button>
        </div>
        
        <div className={`wy-rl-screen s-2 ${screen === '2-factor' ? 'active' : ''}`}>
          <button className="wy-rl-back-button" onClick={e => setScreen('signin-password')}>Back</button>
          <div className="wy-rl-email" onClick={e => setScreen('welcome')}>{email} (change)</div>
          <div className="wy-rl-logo"></div>
          <h2 className="wy-rl-title">2-Factor Enabled</h2>
          <p className="wy-rl-subtitle">Additional securty features enabled.</p>
          <label className="wy-rl-label" htmlFor="token">Enter Authenticator Code</label>
          <input type="text" id="token" disabled={loading} value={token} onChange={e => setToken(e.target.value.replace(/^\D+/g, '').slice(0, 6))} ref={tokenInput} onKeyPress={e => { if (e.key === 'Enter') { signIn2FactorHandler(); }}}/>
          <span className="wy-rl-caption">
            <span className="wy-rl-success">{validationSuccess}</span>
            <span className="wy-rl-warning">{validationWarning}</span>
            <span className="wy-rl-error">{validationError}</span>
          </span>
          <button className="wy-rt-classic-button" onClick={signIn2FactorHandler}>
            <span>Sign In</span>
            <div className={`wy-rt-spinner ${loading ? 'active' : ''}`}>
              <div className="wy-rt-button-spinner"><span></span></div>
            </div>
          </button>
        </div>
        
        <div className={`wy-rl-screen s-2 ${screen === 'platforms' ? 'active' : ''}`}>
          <div className="wy-rl-email" onClick={e => setScreen('welcome')}>{email} (change)</div>
          <div className="wy-rl-logo"></div>
          <br />
          {platform &&
            <p className="wy-rl-subtitle">You do not currently have access to this platform, please submit a request.</p>
          }
          {!platform &&
            <p className="wy-rl-subtitle">Choose a platform to visit</p>
          }
          {platform &&
            <button className="wy-rt-classic-button mb-2" onClick={requestAccess}>Request Access</button>
          }
          <div className="wy-rl-platforms">
            {user.platforms.includes('programmatic') &&
              <div className="wy-rl-platform">
                {/*<div className="wy-rl-platform-name">Programmatic</div>*/}
                <button className="wy-rt-classic-button" onClick={e => platformsHandler('programmatic')}>
                  <span className="button-text">Programmatic Platform</span>
                  <div className="wyi wyi-center wyi-external-link"></div>
                </button>
              </div>
            }
            {user.platforms.includes('publishers') &&
              <div className="wy-rl-platform">
                {/*<div className="wy-rl-platform-name">Publisher</div>*/}
                <button className="wy-rt-classic-button" onClick={e => platformsHandler('publishers')}>
                  <span className="button-text">Publishers Platform</span>
                  <div className="wyi wyi-center wyi-external-link"></div>
                </button>
              </div>
            }
            {user.platforms.includes('ai') &&
              <div className="wy-rl-platform">
                {/*<div className="wy-rl-platform-name">AI Studio</div>*/}
                <button className="wy-rt-classic-button" onClick={e => platformsHandler('ai')}>
                  <span className="button-text">AI Studio</span>
                  <div className="wyi wyi-center wyi-external-link"></div>
                </button>
              </div>
            }
            {user.platforms.includes('yield') &&
              <div className="wy-rl-platform">
                {/*<div className="wy-rl-platform-name">Yield</div>*/}
                <button className="wy-rt-classic-button" onClick={e => platformsHandler('yield')}>
                  <span className="button-text">Yield Platform</span>
                  <div className="wyi wyi-center wyi-external-link"></div>
                </button>
              </div>
            }
          </div>
        </div>
        
        <div className={`wy-rl-screen s-2 ${screen === 'verification' ? 'active' : ''}`}>
          <button className="wy-rl-back-button" onClick={e => setScreen('email')}>Back</button>
          <div className="wy-rl-email" onClick={e => setScreen('welcome')}>{email} (change)</div>
          <div className="wy-rl-logo"></div>
          <h2 className="wy-rl-title">Sign up</h2>
          <p className="wy-rl-subtitle">Verify Your Email</p>
          <label className="wy-rl-label" htmlFor="verification_1">Verification Code</label>
          <input type="text" id="verification_1" disabled={loading} value={verificationCode} onChange={e => setVerificationCode(e.target.value.trim().toUpperCase().substring(0, 6))} ref={verificationCodeInput} onKeyPress={e => { if (e.key === 'Enter') { verificationHandler(); }}}/>
          <span className="wy-rl-caption">
            <span className="wy-rl-success">{validationSuccess}</span>
            <span className="wy-rl-warning">{validationWarning}</span>
            <span className="wy-rl-error">{validationError}</span>
          </span>
          {/*codeExpired && <h6>Verification code has expired.</h6>*/}
          {/*<button className="wy-rt-classic-button" onClick={resendConfirmation}>Resend</button>*/}
          <button className="wy-rt-classic-button" onClick={verificationHandler}>
            <span>Next</span>
            <div className={`wy-rt-spinner ${loading ? 'active' : ''}`}>
              <div className="wy-rt-button-spinner"><span></span></div>
            </div>
          </button>
          <button className="wy-rt-text-button" onClick={resendVerificationCode}>Resend verification code</button>
        </div>
        
        <div className={`wy-rl-screen s-2 ${screen === 'signup' ? 'active' : ''}`}>
          <button className="wy-rl-back-button" onClick={e => setScreen('email')}>Back</button>
          <div className="wy-rl-email" onClick={e => setScreen('welcome')}>{email} (change)</div>
          <div className="wy-rl-logo"></div>
          <h2 className="wy-rl-title"></h2>
          <p className="wy-rl-subtitle">Complete WowYow Sign Up</p>
          <div className="wy-rl-password-rules">
            <div className={`wy-rl-password-rule ${password.trim().length >= 10 ? 'valid' : ''}`}>At least 10 characters long</div>
            <div className={`wy-rl-password-rule ${password.match(/^(?=.*[A-Z])/g) ? 'valid' : ''}`}>At least 1 Uppercase Letter</div>
            <div className={`wy-rl-password-rule ${password.match(/^(?=.*[a-z])/g) ? 'valid' : ''}`}>At least 1 Lowercase Letter</div>
            <div className={`wy-rl-password-rule ${password.match(/\d{1,}/g) ? 'valid' : ''}`}>At least 1 Number</div>
          </div>
          <label className="wy-rl-label" htmlFor="password_2">Password</label>
          <div className="wy-rl-input-group">
            <input className="wy-rl-password" disabled={loading} type={showPassword ? 'text' : 'password'} id="password_2" value={password} onChange={e => setPassword(e.target.value)} ref={signupPasswordInput} onKeyPress={e => { if (e.key === 'Enter') { signUpHandler(); }}}/>
            <span className="wy-rl-show-password" onClick={e => setShowPassword(!showPassword)}>{showPassword ? 'Hide' : 'Show'}</span>
          </div>
          <label className="wy-rl-label" htmlFor="rpassword_2">Repeat Password</label>
          <div className="wy-rl-input-group">
            <input className="wy-rl-password" disabled={loading} type={showRPassword ? 'text' : 'password'} id="rpassword_2" value={rpassword} onChange={e => setRPassword(e.target.value)} onKeyPress={e => { if (e.key === 'Enter') { signUpHandler(); }}}/>
            <span className="wy-rl-show-password" onClick={e => setShowRPassword(!showRPassword)}>{showRPassword ? 'Hide' : 'Show'}</span>
            <span className="wy-rl-caption">
              <span className="wy-rl-success">{validationSuccess}</span>
              <span className="wy-rl-warning">{validationWarning}</span>
              <span className="wy-rl-error">{validationError}</span>
            </span>
          </div>
          <button className="wy-rt-classic-button" onClick={signUpHandler}>
            <span>Sign Up</span>
            <div className={`wy-rt-spinner ${loading ? 'active' : ''}`}>
              <div className="wy-rt-button-spinner"><span></span></div>
            </div>
          </button>
        </div>
        
        <div className={`wy-rl-screen s-2 ${screen === 're-verification' ? 'active' : ''}`}>
          <button className="wy-rl-back-button" onClick={e => setScreen('signin-password')}>Back</button>
          <div className="wy-rl-email" onClick={e => setScreen('welcome')}>{email} (change)</div>
          <div className="wy-rl-logo"></div>
          <h2 className="wy-rl-title">Password Reset</h2>
          <p className="wy-rl-subtitle">Enter Verification Code</p>
          <label className="wy-rl-label" htmlFor="verification_2">Verification Code</label>
          <input type="text" id="verification_2" disabled={loading} value={reVerificationCode} onChange={e => setReVerificationCode(e.target.value.trim().toUpperCase().substring(0, 6))} onKeyPress={e => { if (e.key === 'Enter') { reVerificationHandler(); }}}/>
          <span className="wy-rl-caption">
            <span className="wy-rl-success">{validationSuccess}</span>
            <span className="wy-rl-warning">{validationWarning}</span>
            <span className="wy-rl-error">{validationError}</span>
          </span>
          {/*codeExpired && <h6>Verification code has expired.</h6>*/}
          {/*<button className="wy-rt-classic-button" onClick={resendConfirmation}>Resend</button>*/}
          <button className="wy-rt-classic-button" onClick={reVerificationHandler}>
            <span>Next</span>
            <div className={`wy-rt-spinner ${loading ? 'active' : ''}`}>
              <div className="wy-rt-button-spinner"><span></span></div>
            </div>
          </button>
          <button className="wy-rt-text-button" onClick={resendReVerificationCode}>Resend verification code</button>
        </div>
        
        <div className={`wy-rl-screen s-2 ${screen === 'reset-password' ? 'active' : ''}`}>
          <button className="wy-rl-back-button" onClick={e => setScreen('signin-password')}>Back</button>
          <div className="wy-rl-email" onClick={e => setScreen('welcome')}>{email} (change)</div>
          <div className="wy-rl-logo"></div>
          <h2 className="wy-rl-title"></h2>
          <p className="wy-rl-subtitle">Choose a new password</p>
          <div className="wy-rl-password-rules">
            <div className={`wy-rl-password-rule ${password.trim().length >= 10 ? 'valid' : ''}`}>At least 10 characters long</div>
            <div className={`wy-rl-password-rule ${password.match(/^(?=.*[A-Z])/g) ? 'valid' : ''}`}>At least 1 Uppercase Letter</div>
            <div className={`wy-rl-password-rule ${password.match(/^(?=.*[a-z])/g) ? 'valid' : ''}`}>At least 1 Lowercase Letter</div>
            <div className={`wy-rl-password-rule ${password.match(/\d{1,}/g) ? 'valid' : ''}`}>At least 1 Number</div>
          </div>
          <label className="wy-rl-label" htmlFor="password_3">Password</label>
          <div className="wy-rl-input-group">
            <input className="wy-rl-password" disabled={loading} type={showPassword ? 'text' : 'password'} id="password_3" value={password} onChange={e => setPassword(e.target.value)} onKeyPress={e => { if (e.key === 'Enter') { resetPasswordHandler(); }}}/>
            <span className="wy-rl-show-password" onClick={e => setShowPassword(!showPassword)}>{showPassword ? 'Hide' : 'Show'}</span>
          </div>
          <label className="wy-rl-label" htmlFor="rpassword_1">Repeat Password</label>
          <div className="wy-rl-input-group">
            <input className="wy-rl-password" disabled={loading} type={showRPassword ? 'text' : 'password'} id="rpassword_1" value={rpassword} onChange={e => setRPassword(e.target.value)} onKeyPress={e => { if (e.key === 'Enter') { resetPasswordHandler(); }}}/>
            <span className="wy-rl-show-password" onClick={e => setShowRPassword(!showRPassword)}>{showRPassword ? 'Hide' : 'Show'}</span>
            <span className="wy-rl-caption">
              <span className="wy-rl-success">{validationSuccess}</span>
              <span className="wy-rl-warning">{validationWarning}</span>
              <span className="wy-rl-error">{validationError}</span>
            </span>
          </div>
          <button className="wy-rt-classic-button" onClick={resetPasswordHandler}>
            <span>Reset Password</span>
            <div className={`wy-rt-spinner ${loading ? 'active' : ''}`}>
              <div className="wy-rt-button-spinner"><span></span></div>
            </div>
          </button>
        </div>
        
        <div className={`wy-rl-loading ${loading && !['email', 'signin-password', '2-factor', 'verification', 'signup', 're-verification', 'reset-password'].includes(screen) ? 'active' : ''}`}></div>
        
      </div>
    </div>
  );
}

export {
  WYLoginPopup
};
